import api from '@/libs/axios'

export default {
  async getList(params) {
    let response = await api.get('/admin/special-offers', { params })
    return response.data?.data || null
  },
  async getDetail(params){
    let response = await api.get('/admin/special-offers/detail', { params })
    return response.data?.data?.document || null
  },
  async create(payload) {
    const response = await api.post('/admin/special-offers', payload)
    return response.data.data.new_document || null
  },
  async update(payload) {
    let response = await api.put('/admin/special-offers', payload)
    return response
  },
  async delete(payload) {
    const response = await api.delete('/admin/special-offers', {
      data: payload,
    })
    return response
  },
}
